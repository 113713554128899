<template>
  <div>
    <sui-container>
      <sui-form>
        <sui-form-field>
          <label>{{ $t("panel.raports.label") }}</label>
          <sui-form-fields fields="two">
            <sui-form-field>
              <Datepicker
                :placeholder="$t('panel.raports.startDate')"
                v-model="startDate"
                :language="pl"
                @input="getData"
              />
            </sui-form-field>
            <sui-form-field>
              <Datepicker
                :placeholder="$t('panel.raports.endDate')"
                v-model="endDate"
                :disabled="startDate === ''"
                :language="pl"
                @input="getData"
              />
            </sui-form-field>
          </sui-form-fields>
        </sui-form-field>
      </sui-form>
    </sui-container>

    <sui-loader v-if="loading" :active="loading" centered inline />

    <sui-container v-else-if="!loading && startDate && endDate">
      <sui-grid v-if="Object.keys(raports.users_dates).length > 0">
        <sui-grid-row>
          <sui-grid-column>
            <h2 is="sui-header" icon text-align="center">
              {{ $t("panel.raports.users") }}
            </h2>
            <line-chart
              :data="raports.users_dates"
              v-if="checkObjectValues(raports.users_dates)"
              :download="true"
              :colors="['#C22336']"
            />
            <h4 v-else is="sui-header" icon text-align="center">
              {{ $t("panel.raports.noData") }}
            </h4>
          </sui-grid-column>
        </sui-grid-row>
      </sui-grid>
      <sui-grid v-if="Object.keys(raports.projects_dates).length > 0">
        <sui-grid-row>
          <sui-grid-column>
            <h2 is="sui-header" icon text-align="center">
              {{ $t("panel.raports.projects") }}
            </h2>
            <line-chart
              :data="raports.projects_dates"
              v-if="checkObjectValues(raports.projects_dates)"
              :download="true"
              :colors="['#C22336']"
            />
            <h4 v-else is="sui-header" icon text-align="center">
              {{ $t("panel.raports.noData") }}
            </h4>
          </sui-grid-column>
        </sui-grid-row>
      </sui-grid>
      <sui-grid>
        <sui-grid-row>
          <sui-grid-column>
            <h2 is="sui-header" icon text-align="center">
              {{ $t("panel.raports.usersTypes") }}
            </h2>
            <column-chart
              :data="raports.users_types"
              v-if="Object.keys(raports.users_types).length > 0"
              :download="true"
              :colors="['#C22336']"
            />
            <h4 v-else is="sui-header" icon text-align="center">
              {{ $t("panel.raports.noData") }}
            </h4>
          </sui-grid-column>
        </sui-grid-row>
      </sui-grid>
      <sui-grid>
        <sui-grid-row>
          <sui-grid-column>
            <h2 is="sui-header" icon text-align="center">
              {{ $t("panel.raports.projectsTypes") }}
            </h2>
            <column-chart
              :data="raports.projects_types"
              v-if="Object.keys(raports.projects_types).length > 0"
              :download="true"
              :colors="['#C22336']"
            />
            <h4 v-else is="sui-header" icon text-align="center">
              {{ $t("panel.raports.noData") }}
            </h4>
          </sui-grid-column>
        </sui-grid-row>
      </sui-grid>
      <sui-grid
        divided="vertically"
        v-if="
          Object.keys(raports.users).length > 0 ||
            Object.keys(raports.projects).length > 0
        "
      >
        <sui-grid-row :columns="2">
          <sui-grid-column v-if="Object.keys(raports.users).length > 0">
            <h2 is="sui-header" icon text-align="center">
              {{ $t("panel.raports.usersVoivodeship") }}
            </h2>
            <pie-chart
              :data="raports.users"
              v-if="checkObjectValues(raports.users)"
              :download="true"
            />
            <h4 v-else is="sui-header" icon text-align="center">
              {{ $t("panel.raports.noData") }}
            </h4>
          </sui-grid-column>
          <sui-grid-column v-if="Object.keys(raports.projects).length > 0">
            <h2 is="sui-header" icon text-align="center">
              {{ $t("panel.raports.projectsVoivodeship") }}
            </h2>
            <pie-chart
              :data="raports.projects"
              v-if="checkObjectValues(raports.projects)"
              :download="true"
            />
            <h4 v-else is="sui-header" icon text-align="center">
              {{ $t("panel.raports.noData") }}
            </h4>
          </sui-grid-column>
        </sui-grid-row>
      </sui-grid>
    </sui-container>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { pl } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      pl: pl
    };
  },
  methods: {
    ...mapActions({
      _getRaports: "panel/getRaports"
    }),
    getData: function() {
      if (this.startDate && this.endDate) {
        let start = moment(this.startDate).format("YYYY-MM-DD");
        let end = moment(this.endDate).format("YYYY-MM-DD");
        this._getRaports({ start: start, end: end });
      }
    },
    checkObjectValues: function(object) {
      for (let key of Object.keys(object)) {
        if (object[key] > 0) return true;
      }

      return false;
    }
  },
  computed: {
    ...mapState({
      loading: state => {
        return state.panel.loading.raports;
      },
      raports: state => {
        return state.panel.raports;
      }
    })
  }
};
</script>

<style scoped></style>
