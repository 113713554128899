<template>
  <sui-container fluid>
    <sui-accordion exclusive fluid>
      <h4 v-if="orders.length > 0">{{ $t("panel.projects.label") }}:</h4>
      <span v-for="(order, index) in orders" v-bind:key="order.order_id">
        <sui-accordion-title>
          <sui-icon name="dropdown" />
          <b>{{ order.name }}</b>
        </sui-accordion-title>
        <sui-accordion-content>
          <table class="ui table striped">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("panel.projects.room") }}</th>
                <th>{{ $t("panel.projects.area") }} [m<sup>2</sup>]</th>
                <th>{{ $t("panel.projects.air_stream") }} [m<sup>3</sup>]</th>
                <th>{{ $t("panel.projects.type") }}</th>
              </tr>
            </thead>
            <tbody v-for="(room, i) in orders[index].rooms" :key="i">
              <tr>
                <td class="collapsing">{{ i + 1 }}</td>
                <td class="collapsing">{{ room.name }}</td>
                <td class="collapsing">{{ replaceWithComma(room.area) }}</td>
                <td class="collapsing">{{ room.air_stream }}</td>
                <td class="collapsing">
                  {{ $t("rooms.list.table." + room.room_type) }}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="ui stackable right aligned grid">
            <div class="ten wide right floated right aligned column">
              <span v-on:click="restoreOrder(index)" class="links">
                <sui-icon name="edit" />
                {{ $t("panel.projects.create") }}
              </span>
            </div>
            <a
              :href="order.html"
              target="_blank"
              class="pdf-link two wide right floated column"
            >
              <span class="links">
                <sui-icon name="file pdf outline" />
                {{ $t("panel.projects.pdf") }}
              </span>
            </a>
            <div class="two wide right floated column">
              <span v-on:click="remove(order.order_id)" class="links">
                <sui-icon name="trash alternate" />
                {{ $t("panel.projects.delete") }}
              </span>
            </div>
          </div>
        </sui-accordion-content>
      </span>
    </sui-accordion>
  </sui-container>
</template>

<script>
export default {
  props: {
    orders: {
      required: true,
      type: Array
    }
  },
  methods: {
    restoreOrder: function(index) {
      this.$store
        .dispatch("rooms/restoreOrder", this.orders[index])
        .then(() => {
          this.$router.push({
            name: "home"
          });
        });
    },
    restorePDF: function(index) {
      this.$store
        .dispatch("rooms/restorePDF", this.orders[index])
        .then(() => {});
    },
    remove: function(id) {
      if (confirm(this.$t("panel.projects.delete_question"))) {
        this.$store.dispatch("orders/deleteOrder", id).then(() => {});
      }
    },
    replaceWithComma(string) {
      let floatNumber = parseFloat(string).toFixed(2);
      string = floatNumber.toString();
      return string.replace(".", ",");
    }
  }
};
</script>

<style scoped>
.no-data {
  margin-bottom: 20px;
}
.pdf-link {
  color: rgba(0, 0, 0, 0.87);
}
</style>
