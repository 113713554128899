<template>
  <table class="ui very basic celled table">
    <tbody>
      <tr>
        <td class="collapsing">
          <h4 class="ui image header">
            <div class="content">
              {{ $t("panel.unactive_users.table.phone") }}
            </div>
          </h4>
        </td>
        <td>
          {{ user.phone }}
        </td>
      </tr>
      <tr>
        <td class="collapsing">
          <h4 class="ui image header">
            <div class="content">
              {{ $t("panel.unactive_users.table.email") }}
            </div>
          </h4>
        </td>
        <td>
          {{ user.email }}
        </td>
      </tr>
      <tr>
        <td class="collapsing">
          <h4 class="ui image header">
            <div class="content">
              {{ $t("panel.unactive_users.table.username") }}
            </div>
          </h4>
        </td>
        <td>
          {{ user.username }}
        </td>
      </tr>
      <tr>
        <td class="collapsing">
          <h4 class="ui image header">
            <div class="content">
              {{ $t("panel.unactive_users.table.userType") }}
            </div>
          </h4>
        </td>
        <td>
          {{ user.userType }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    user: {
      required: true,
      type: Object
    }
  }
};
</script>
