<template>
  <div class="profile">
    <sui-container fluid>
      <sui-tab active-index="0">
        <sui-tab-pane active :title="$t('panel.tabs.newUsers')">
          <PanelIndex />
        </sui-tab-pane>
        <sui-tab-pane :title="$t('panel.tabs.users')">
          <PanelUsers />
        </sui-tab-pane>
        <sui-tab-pane :title="$t('panel.tabs.projects')">
          <PanelProjects />
        </sui-tab-pane>
        <sui-tab-pane :title="$t('panel.tabs.raports')">
          <PanelRaports />
        </sui-tab-pane>
      </sui-tab>
    </sui-container>
  </div>
</template>

<script>
import PanelIndex from "@/components/panel/Index.vue";
import PanelProjects from "@/components/panel/Projects.vue";
import PanelRaports from "@/components/panel/Raports.vue";
import PanelUsers from "@/components/panel/Users.vue";

export default {
  components: {
    PanelIndex,
    PanelProjects,
    PanelRaports,
    PanelUsers
  }
};
</script>
