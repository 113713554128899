<template>
  <div>
    <sui-loader v-if="loading" :active="loading" centered inline />
    <div v-else>
      <transition name="fade">
        <sui-message
          v-if="message"
          :content="messageText"
          dismissable
          @dismiss="handleDismiss"
        />
      </transition>
      <sui-table single-line striped v-if="unactiveUsers.length > 0">
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.firstname")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.lastname")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.projects")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.email")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.phone")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.voivodeship")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.region")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.userType")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.nip")
            }}</sui-table-header-cell>
            <sui-table-header-cell></sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>
        <sui-table-body>
          <sui-table-row v-for="item in unactiveUsers" :key="item.user_id">
            <sui-table-cell>{{ item.firstname }}</sui-table-cell>
            <sui-table-cell>{{ item.lastname }}</sui-table-cell>
            <sui-table-cell>{{ item.projectsCount }}</sui-table-cell>
            <sui-table-cell>{{ item.email }}</sui-table-cell>
            <sui-table-cell>{{ item.phone }}</sui-table-cell>
            <sui-table-cell>{{ item.voivodeship }}</sui-table-cell>
            <sui-table-cell>{{ item.region }}</sui-table-cell>
            <sui-table-cell>{{ item.userType }}</sui-table-cell>
            <sui-table-cell>{{
              item.nip || $t("panel.unactive_users.table.no_nip")
            }}</sui-table-cell>
            <sui-table-cell>
              <sui-button @click="activateUser(item.user_id)">
                {{ $t("panel.unactive_users.table.activate") }}
              </sui-button></sui-table-cell
            >
          </sui-table-row>
        </sui-table-body>
      </sui-table>
      <h2 v-else is="sui-header">
        {{ $t("panel.unactive_users.table.no_data") }}
      </h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Index",
  data() {
    return {
      message: false,
      messageText: ""
    };
  },
  methods: {
    ...mapActions({
      _getUnactiveUsers: "panel/getUnactiveUsers",
      _activateUser: "panel/activateUser"
    }),
    handleDismiss() {
      this.message = false;
    },
    activateUser: function(id) {
      this._activateUser(id)
        .then(() => {
          this.message = true;
          this.messageText = this.$t(
            "panel.unactive_users.table.user_activated"
          );
          this._getUnactiveUsers();
        })
        .catch(() => {
          this.message = true;
          this.messageText = this.$t(
            "panel.unactive_users.table.user_not_activated"
          );
        });
    }
  },
  computed: {
    ...mapState({
      loading: state => {
        return state.panel.loading.unactiveUsers;
      },
      unactiveUsers: state => {
        return state.panel.unactiveUsers || [];
      }
    })
  },
  mounted() {
    this._getUnactiveUsers();
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
