<template>
  <div>
    <sui-loader v-if="loading" :active="loading" centered inline />
    <div v-else>
      <transition name="fade">
        <sui-message
          v-if="message"
          :content="messageText"
          dismissable
          @dismiss="handleDismiss"
        />
      </transition>
      <sui-table single-line striped v-if="users.length > 0">
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.firstname")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.lastname")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.projects")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.email")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.phone")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.voivodeship")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.region")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.userType")
            }}</sui-table-header-cell>
            <sui-table-header-cell>{{
              $t("panel.unactive_users.table.nip")
            }}</sui-table-header-cell>
            <sui-table-header-cell></sui-table-header-cell>
            <sui-table-header-cell></sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>
        <sui-table-body>
          <sui-table-row v-for="item in users" :key="item.user_id">
            <sui-table-cell>{{ item.firstname }}</sui-table-cell>
            <sui-table-cell>{{ item.lastname }}</sui-table-cell>
            <sui-table-cell>{{ item.projectsCount }}</sui-table-cell>
            <sui-table-cell>{{ item.email }}</sui-table-cell>
            <sui-table-cell>{{ item.phone }}</sui-table-cell>
            <sui-table-cell>{{ item.voivodeship }}</sui-table-cell>
            <sui-table-cell>{{ item.region }}</sui-table-cell>
            <sui-table-cell>{{ item.userType }}</sui-table-cell>
            <sui-table-cell>{{
              item.nip || $t("panel.unactive_users.table.no_nip")
            }}</sui-table-cell>
            <sui-table-cell>
              <sui-button @click="userSchedule(item)">
                {{ $t("panel.users.table.schedule") }}
              </sui-button></sui-table-cell
            >
            <sui-table-cell>
              <sui-button @click="userDeactivate(item.user_id)">
                {{ $t("panel.users.table.deactivate") }}
              </sui-button></sui-table-cell
            >
          </sui-table-row>
        </sui-table-body>
      </sui-table>
      <h2 v-else is="sui-header">
        {{ $t("panel.users.table.no_data") }}
      </h2>

      <sui-modal v-model="modal">
        <sui-modal-content>
          <sui-modal-description v-if="loadingNewTask">
            <sui-loader centered inline />
          </sui-modal-description>
          <sui-modal-description v-else>
            <sui-header
              >{{ $t("panel.users.table.schedule") }} - {{ user.firstname }}
              {{ user.lastname }}</sui-header
            >
            <div>
              <sui-table
                single-line
                v-if="user.scheduled_tasks && user.scheduled_tasks.length > 0"
              >
                <sui-table-header>
                  <sui-table-row>
                    <sui-table-header-cell>{{
                      $t("panel.users.table.action.date")
                    }}</sui-table-header-cell>
                    <sui-table-header-cell>{{
                      $t("panel.users.table.action.type")
                    }}</sui-table-header-cell>
                    <sui-table-header-cell />
                  </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                  <sui-table-row
                    v-for="item in user.scheduled_tasks"
                    :key="item.id"
                  >
                    <sui-table-cell>{{ formatDate(item.date) }}</sui-table-cell>
                    <sui-table-cell>{{
                      $t(`panel.users.table.action.${item.action}`)
                    }}</sui-table-cell>
                    <sui-table-cell>
                      <sui-button @click.native="deleteTask(item.id)"
                        >{{ $t(`panel.users.table.action.delete`) }}
                      </sui-button></sui-table-cell
                    >
                  </sui-table-row>
                </sui-table-body>
              </sui-table>

              <h3 v-else is="sui-header" icon text-align="center">
                {{ $t("panel.users.table.action.noTasks") }}
              </h3>

              <sui-form>
                <label>{{ $t("panel.users.table.action.newTask") }}</label>
                <sui-form-fields fields="three">
                  <sui-form-field>
                    <Datepicker
                      :placeholder="$t('panel.users.table.action.date')"
                      v-model="date"
                      :language="pl"
                    />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-dropdown
                      :placeholder="$t('panel.users.table.action.hour')"
                      selection
                      :options="hours"
                      v-model="hour"
                    />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-dropdown
                      :placeholder="$t('panel.users.table.action.type')"
                      selection
                      :options="types"
                      v-model="type"
                    />
                  </sui-form-field>
                </sui-form-fields>
              </sui-form>

              <sui-button positive @click.native="addTask">
                {{ $t("panel.users.table.action.add") }}
              </sui-button>
            </div>
          </sui-modal-description>
        </sui-modal-content>
        <sui-modal-actions>
          <sui-button negative @click.native="dismissModal">
            {{ $t("panel.users.table.action.cancel") }}
          </sui-button>
        </sui-modal-actions>
      </sui-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { pl } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      message: false,
      messageText: "",
      modal: false,
      user: {},
      pl: pl,
      date: null,
      hour: null,
      type: null,
      hours: [
        { text: "00:00", value: "00:00" },
        { text: "01:00", value: "01:00" },
        { text: "02:00", value: "02:00" },
        { text: "03:00", value: "03:00" },
        { text: "04:00", value: "04:00" },
        { text: "05:00", value: "05:00" },
        { text: "06:00", value: "06:00" },
        { text: "07:00", value: "07:00" },
        { text: "08:00", value: "08:00" },
        { text: "09:00", value: "09:00" },
        { text: "10:00", value: "10:00" },
        { text: "11:00", value: "11:00" },
        { text: "12:00", value: "12:00" },
        { text: "13:00", value: "13:00" },
        { text: "14:00", value: "14:00" },
        { text: "15:00", value: "15:00" },
        { text: "16:00", value: "16:00" },
        { text: "17:00", value: "17:00" },
        { text: "18:00", value: "18:00" },
        { text: "19:00", value: "19:00" },
        { text: "20:00", value: "20:00" },
        { text: "21:00", value: "21:00" },
        { text: "22:00", value: "22:00" },
        { text: "23:00", value: "23:00" }
      ],
      types: [
        {
          text: this.$t("panel.users.table.action.unpublish"),
          value: "unpublish"
        },
        {
          text: this.$t("panel.users.table.action.publish"),
          value: "publish"
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      _getUsers: "panel/getUsers",
      _updateUserTasks: "panel/updateUserTasks",
      _removeUserTasks: "panel/removeUserTasks",
      _userDeactivate: "panel/userDeactivate"
    }),
    handleDismiss() {
      this.message = false;
    },
    userSchedule: function(user) {
      this.modal = true;
      this.user = user;
    },
    dismissModal: function() {
      this.modal = false;
      this.user = {};
    },
    formatDate: function(val) {
      return moment.unix(val).format("YYYY-MM-DD HH:mm");
    },
    addTask: function() {
      let date = moment(this.date).format("YYYY-MM-DD");
      let hour = this.hour;
      let taskTime = moment(`${date} ${hour}`, "YYYY-MM-DD HH:mm").unix();

      let newTask = {
        cid: this.user.user_id,
        ctype: "object",
        action: this.type,
        active: true,
        date: taskTime
      };

      this.hour = null;
      this.date = null;
      this.type = null;

      this._updateUserTasks({
        id: this.user.user_id,
        newTask: newTask
      }).then(data => {
        this.user = data;
        this._getUsers();
      });
    },
    deleteTask: function(id) {
      this._removeUserTasks({
        id: id,
        user: this.user
      }).then(data => {
        this.user = data;
        this._getUsers();
      });
    },
    userDeactivate: function(id) {
      this._userDeactivate({ id: id }).then(() => {
        this._getUsers();
      });
    }
  },
  computed: {
    ...mapState({
      loading: state => {
        return state.panel.loading.users;
      },
      users: state => {
        return state.panel.users || [];
      },
      loadingNewTask: state => {
        return state.panel.loading.tasks;
      }
    })
  },
  mounted() {
    this._getUsers();
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.ui.standard.modal.transition.visible.active {
  padding-top: 0;
}
</style>
