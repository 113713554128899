<template>
  <div>
    <sui-container>
      <sui-loader v-if="loading" :active="loading" centered inline />
      <div v-else>
        <sui-accordion
          exclusive
          fluid
          v-for="(voivodeship, voivodeshipKey) in projects"
          :key="voivodeshipKey"
        >
          <sui-accordion-title>
            <sui-icon name="dropdown" />
            <strong>{{ voivodeshipKey }}</strong>
          </sui-accordion-title>

          <sui-accordion-content>
            <sui-accordion
              exclusive
              fluid
              v-for="(user, userKey) in voivodeship"
              :key="userKey"
              class="nested"
            >
              <sui-accordion-title>
                <sui-icon name="dropdown" />
                {{ user.firstname }} {{ user.lastname }}
              </sui-accordion-title>

              <sui-accordion-content>
                <div class="accordion-data">
                  <UserTable :user="user" />
                  <ProjectsList :orders="user.orders" />
                </div>
              </sui-accordion-content>
            </sui-accordion>
          </sui-accordion-content>
        </sui-accordion>
      </div>
    </sui-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UserTable from "@/components/panel/Projects/UserTable.vue";
import ProjectsList from "@/components/panel/Projects/ProjectsList.vue";

export default {
  components: {
    UserTable,
    ProjectsList
  },
  methods: {
    ...mapActions({
      _getProjects: "panel/getProjects"
    })
  },
  computed: {
    ...mapState({
      loading: state => {
        return state.panel.loading.projects;
      },
      projects: state => {
        return state.panel.projects || [];
      }
    })
  },
  mounted() {
    this._getProjects();
  }
};
</script>

<style scoped>
.nested.ui.fluid.accordion {
  margin-top: -20px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.accordion-data {
  padding: 0 20px;
}
</style>
